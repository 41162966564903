<!-- eslint-disable -->
<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="dataSource" rowKey="_id" :loading="loading">
      <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>
      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #customRender="{ text, column }">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              class="highlight"
              :key="i"
            >
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template #utilisateur="{ text }">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
        </template>
      </template>
      <template #title="{ text }">
        <span>{{ text }}</span>
      </template>
      <template #seen="{ text }">
        <span>{{ text }}</span>
      </template>
      <template #audio="{ text, record }">
        <div class="editable-cell">
          <div class="editable-cell-text-wrapper">
            <edit-outlined
              class="editable-cell-icon"
              :style="{ fontSize: '20px' }"
              @click="changeAudio(record)"
            />
          </div>
          <div v-if="text">
            <audio controls style="width: 90%" >
              <source :src="apiUrl + text" type="audio/mpeg" />
            </audio>
          </div>
          <div v-else>
            <p>Audio introuvable</p>
          </div>
        </div>
      </template>
      <template #discription="{ text, record }">
        <div class="editable-cell">
          <div v-if="editableData[record._id]" class="editable-cell-input-wrapper">
            <a-input
              v-model:value="editableData[record._id].discription"
              @pressEnter="save(record)"
            />
            <check-outlined class="editable-cell-icon-check" @click="save(record)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
            <edit-outlined class="editable-cell-icon" @click="edit(record)" />
          </div>
        </div>
      </template>
      <template #status="{ text }">
        <span
          :class="[
            text === 'inactive'
              ? 'font-size-12 badge badge-primary'
              : 'font-size-12 badge badge-success',
          ]"
          >{{ text === 'inactive' ? 'Inactive' : 'Active' }}</span
        >
      </template>
      <template #action="{ record }">
        <span>
          <a class="btn btn-sm btn-light" @click="suppPub(record)">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            {{ record.status == 'active' ? 'Désactiver' : 'Activer' }}
          </a>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visibleModal"
      title="Modifier l'audio"
      :width="400"
      style="height: 100px"
      okText="Modifier"
      @ok="onSubmit"
      :ok-button-props="{ loading: loadingOk }"
      @cancel="cancel"
    >
      <a-form
        label-align="left"
        class="container"
        :rules="rules"
        :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 12 }"
      >
        <div class="row">
          <div class="col md-6">
            <a-upload-dragger
              :file-list="fileList"
              name="file"
              :multiple="false"
              @change="handleChange"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              :custom-request="
                () => {
                  return true
                }
              "
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <p class="ant-upload-text">Click or drag file to area to upload</p>
              <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data
                or other band files
              </p>
            </a-upload-dragger>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, computed, reactive, watch } from 'vue'
import ApiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { mapState } from 'vuex'
import { CheckOutlined, CloseOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
export default {
  computed: mapState(['user']),
  components: {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    SearchOutlined,
  },
  props: {
    data: {
      type: Array,
    },
  },
  setup(props) {
    const visibleModal = ref(false)
    const fileList = ref([])
    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const columns = [
      {
        title: 'Audio',
        dataIndex: 'audio',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'audio',
        },
        onFilter: (value, record) => record.audio.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'Discription',
        dataIndex: 'discription',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'discription',
        },
        onFilter: (value, record) => record.discription.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus()
            })
          }
        },
      },
      {
        title: 'status',
        dataIndex: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
        slots: { customRender: 'status' },
      },
      {
        title: 'Action',
        slots: { customRender: 'action' },
      },
    ]
    const dataSource = ref(props.data)
    const loadingOk = ref(false)
    watch(
      () => props.data,
      (first, second) => {
        dataSource.value = first
        loading.value=false
      },
    )
    /* const store = useStore()
    const user = computed(() => store.getters['user/user'])*/
    const count = computed(() => dataSource.value.length + 1)
    const editableData = reactive({})
    const searchText = ref('')
    const searchInput = ref()
    const activeRecord = ref()
    const state = reactive({
      searchText: '',
      searchedColumn: '',
    })

    const visible = ref(false)
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = (clearFilters) => {
      clearFilters()
      searchText.value = ''
    }

    const suppPub = (record) => {
      record.status = record.status == 'active' ? 'inactive' : 'active'
      ApiClient.patch('/podcast/' + record._id, {
        data: { status: record.status },
      })
        .then(() => {
          Object.assign(
            dataSource.value.filter((item) => record._id === item._id),
            record,
          )

          message.success(`Audio est ${record.status == 'inactive' ? 'désactivé' : 'activé'}`)
        })
        .catch((e) => {
          message.warning("Impossible d'activer/désactiver le contenue")
        })
    }
    const edit = (record) => {
      editableData[record._id] = cloneDeep(
        dataSource.value.filter((item) => record._id === item._id)[0],
      )
    }

    const save = (record) => {
      ApiClient.patch('/podcast/' + record._id, {
        data: {
          discription: editableData[record._id].discription,
        },
      })
        .then(() => {
          Object.assign(
            dataSource.value.filter((item) => record._id === item._id)[0],
            editableData[record._id],
          )
          delete editableData[record._id]

          message.success(`Audio modifié`)
        })
        .catch((e) => {
          message.warning('Impossible de modifier le contenue')
        })
    }
    //file upload
    const loading = ref(true)
    const handleChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
    }
    const beforeUpload = (file) => {
      const isMpegOrOgg = file.type === 'audio/mpeg' || file.type === 'audio/ogg'

      if (!isMpegOrOgg) {
        message.error('You can only upload Mp3 or Ogg files!')
      }

      const isLt2M = file.size / 1024 / 1024 < 50

      if (!isLt2M) {
        message.error('Image must smaller than 50MB!')
      }
      if (isMpegOrOgg && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`)
        fileList.value = [...fileList.value, file]
      }
      return isMpegOrOgg && isLt2M
    }
    const handleFinishFailed = (errors) => {
      console.log(errors)
    }
    const changeAudio = (record) => {
      activeRecord.value = record
      visibleModal.value = true
    }
    const onSubmit = () => {
      if (fileList.value.length > 0) {
        loadingOk.value=true
        let formData = new FormData()
        formData.append('audio', fileList.value[0], activeRecord.value._id)
        ApiClient.patch('/podcast/files/' + activeRecord.value._id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((res) => {
            activeRecord.value.audio = res.data.audio

            Object.assign(
              dataSource.value.filter((item) => activeRecord.value._id === item._id)[0],
              activeRecord.value,
            )
          })
          .catch(function () {
            message.error("Impossible de télécharger l'image.")
          })
          .finally(() => {
            loadingOk.value=false
            visibleModal.value = false
          })
      }
    }
    return {
      loadingOk,
      edit,
      save,
      visible,
      searchText: '',
      searchInput,
      columns,
      handleReset,
      handleSearch,
      suppPub,
      dataSource,
      editableData,
      tableLoading: false,
      apiUrl: process.env.VUE_APP_API_URL,
      fileList,
      visibleModal,
      loading,
      handleChange,
      beforeUpload,
      handleRemove,
      onSubmit,
      changeAudio,
    }
  },
}
</script>

<style lang="less" scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
