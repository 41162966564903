<template>
  <a-modal
    v-model:visible="visibleModal"
    title="Ajouter audio (MP3)"
    :bodyStyle="{ height: '370px' }"
    ok-text="Ajouter"
    @ok="onSubmit"
    :ok-button-props="{ loading: loading }"
    @cancel="cancel"
  >
    <a-form
      ref="formRef"
      :model="formState"
      label-align="left"
      class="container"
      :rules="rules"
      :label-col="{ span: 10 }"
      :wrapper-col="{ span: 12 }"
      @finishFailed="handleFinishFailed"
    >
      <div class="row">
        <div class="col md-6">
          <a-form-item label="Discription" name="discription" :v-if="add">
            <a-input v-model:value="formState.discription" placeholder="discription" />
          </a-form-item>
          <a-upload-dragger
            :file-list="fileList"
            name="file"
            :multiple="false"
            :remove="handleRemove"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">Click or drag file to area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or
              other band files
            </p>
          </a-upload-dragger>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
/* eslint-disable */
import { message } from 'ant-design-vue'
import { ref, toRaw, watch, emits, reactive, computed } from 'vue'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons-vue'
import ApiClient from '@/services/axios'
import { mapState } from 'vuex'
export default {
  components: {
    CheckOutlined,
    CloseOutlined,
  },
  computed: mapState(['user']),
  emits: ['addedUser', 'cancel'],
  props: {
    visible: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formRef = ref()
    const visibleModal = ref(false)

    watch(
      () => props.visible,
      (first, second) => {
        visibleModal.value = first
      },
    )
    const rules = {
      discription: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
      ],
    }
    const formState = reactive({
      discription: '',
    })

    /* const onSubmit = () => {
      console.log(formRef.value)
      formRef.value
        .validate().then(() => {
          console.log("validated")
        })
    }*/
    //file upload
    const fileList = ref([])
    const loading = ref(props.loading)
    const handleChange = (info) => {
      console.log(info.file.status)

      if (info.file.status !== 'uploading') {
        console.log(info.file)
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
    }
    const beforeUpload = (file) => {
      const isMpegOrOgg = file.type === 'audio/mpeg' || file.type === 'audio/ogg'

      if (!isMpegOrOgg) {
        message.error('You can only upload Mp3 or Ogg files!')
      }

      const isLt2M = file.size / 1024 / 1024 < 50

      if (!isLt2M) {
        message.error('Image must smaller than 50MB!')
      }
      if (isMpegOrOgg && isLt2M) {
        message.success(`${file.name} fichier acceptée..`)
        fileList.value = [...fileList.value, file]
      }
      return isMpegOrOgg && isLt2M
    }
    const handleFinishFailed = (errors) => {
      console.log(errors)
    }
    return {
      handleFinishFailed,
      visibleModal,
      formState,
      rules,
      formRef,
      fileList,
      loading,
      handleChange,
      beforeUpload,
      handleRemove,
    }
  },
  methods: {
    cancel(e) {
      this.$emit('cancel', false)
    },
    onSubmit() {
      this.formRef
        .validate()
        .then(() => {
          const data = toRaw(this.formState)
          ApiClient.put('/podcast', {
            data: data,
          })
            .then(async (res) => {
              if (this.fileList.length > 0) {
                this.loading = true
                let formData = new FormData()
                formData.append('audio', this.fileList[0], res.data._id)
                await ApiClient.patch('/podcast/files/' + res.data._id, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                  .then((resAudio) => {
                    this.loading = false
                    message.success(`audio ajouté`)
                    res.data.audio = resAudio.data.audio
                  })
                  .catch(function () {
                    this.loading = false
                    message.error("Impossible de télécharger l'audio.")
                  })
              }
              this.$emit('videoAdded', res.data)
            })
            .catch((e) => {
              message.warning("Impossible d'ajouter le podcast.")
            })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
  },
}
</script>
